.main-container-general {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.background {
  background-image: url('../../../public/images/foto_texturas.jpg'); /* Ruta a tu imagen de fondo */
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.long-logo {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 999;
  width: 300px;
  height: auto;
}
.merchan-logo {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 999;
  width: 300px;
  height: auto;
}
.charge-gif {
  width: 30%;
}
.logo {
  margin-top: 4%;
  width: 5%;
}
.line-splash {
  margin-top: 5%;
}
.line-splash-bottom {
  margin-top: 20%;
}
.microsoft-button {
  margin-top: 1%;
  font-family: 'Open Sans', sans-serif;
  background-color: black;
  color: white;
  font-size: 100%;
  height: 50px;
  width: 150px;
}
.microsoft-button:hover {
  margin-top: 1%;
  font-family: 'Graphik Light';
  background-color: white;
  color: black;
  font-size: 100%;
  height: 50px;
  width: 150px;
}
.heading-contact {
  margin-top: 3%;
  font-family: 'Graphik Light';
  font-size: 40px;
}
